<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户昵称：">
        <el-input v-model="searchForm.nickname" size="small"></el-input>
      </el-form-item>
      <el-form-item label="会员昵称：">
        <el-input v-model="searchForm.name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="会员电话：">
        <el-input v-model="searchForm.mobile" size="small"></el-input>
      </el-form-item>
      <el-form-item label="申请日期：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="pay_time" label="申退时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column prop="nickname" label="用户信息" width="200" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="会员姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="会员手机号" align="center"></el-table-column>
      <el-table-column prop="amount" label="申退金额" align="center"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">{{ row.status == 0 ? '待审核' : row.status == 1 ? '已同意' : row.status == 2 ? '已打款' : '已驳回' }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="row.status == 0" @click="agree(row)" type="text" size="small">同意</el-button>
          <el-button v-if="row.status == 1" @click="remit(row)" type="text" size="small">打款</el-button>
          <el-button v-if="row.status == 0" @click="remark(row)" type="text" size="small">驳回</el-button>
          <el-button @click="seeInfo(row)" type="text" size="small">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form class="auditForm" label-width="100px" ref="form" :model="auditForm" :rules="rules">
        <el-form-item v-if="auditForm.status == 2" label="打款金额：" prop="remit_amount">
          <el-input v-model="auditForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item v-if="auditForm.status == 2" label="打款凭证：" prop="voucher">
          <ReadyUploadSource @getSource="val => (auditForm.voucher = val.path)" :isManyMax="1" :path="auditForm.voucher" @removeThis="() => (auditForm.voucher = '')"></ReadyUploadSource>
        </el-form-item>
        <el-form-item v-if="auditForm.status == -1" label="驳回原因：" prop="remark">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="auditForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dislogauditinfo" width="600px">
      <el-form class="auditFormInfo" label-width="100px">
        <el-form-item label="审核结果：">{{ currentInfo.status == 0 ? '待审核' : currentInfo.status == 1 ? '已同意' : currentInfo.status == 2 ? '已打款' : '已驳回' }}</el-form-item>
        <el-form-item label="申退金额：">{{ currentInfo.amount }}</el-form-item>
        <template v-if="currentInfo.status == 2">
          <el-form-item label="打款金额：">{{ currentInfo.remit_amount }}</el-form-item>
          <el-form-item label="打款凭证：">
            <el-image style="width: 100px; height: 70px" :src="currentInfo.voucher" :preview-src-list="[currentInfo.voucher]"></el-image>
          </el-form-item>
        </template>
        <el-form-item v-if="currentInfo.status == -1" label="驳回原因：">{{ currentInfo.remark }}</el-form-item>
        <el-form-item label="持卡人姓名：">{{ currentInfo.bank_name }}</el-form-item>
        <el-form-item label="银行卡号：">{{ currentInfo.bank_card }}</el-form-item>
        <el-form-item label="银行信息：">{{ currentInfo.bank_info }}</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogauditinfo = !1">取 消</el-button>
        <el-button type="primary" @click="dislogauditinfo">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>        

<script>
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';
export default {
  components: {
    Paging,
    ReadyUploadSource,
    userInfo,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        nickname: '',
        start_time: '',
        end_time: '',
      },
      auditForm: {
        status: 1,
        remark: '',
        remit_amount: '',
        voucher: '',
      },
      id: '',
      dislogaudit: !1,
      dislogauditinfo: !1,
      list: [],
      total_number: 0,
      currentInfo: {},
      rules: {
        remark: { required: true, message: '请填写驳回原因', trigger: 'blur' },
        remit_amount: { required: true, message: '请填写打款金额', trigger: 'blur' },
        voucher: { required: true, message: '请添加打款凭证', trigger: 'blur' },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        nickname: '',
        start_time: '',
        end_time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.samecity.depositOut, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    seeInfo(row) {
      this.currentInfo = row;
      this.dislogauditinfo = !0;
    },
    //同意
    agree(row) {
      this.$confirm('请确认是否同意退款？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.samecity.depositOutAudit, {
            id: row.id,
            status: 1,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('已同意退款');
              row.status = 1;
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    //打款
    remit(row) {
      this.id = row.id;
      this.dislogaudit = !0;
      this.auditForm.remit_amount = row.amount;
      this.auditForm.status = 2;
      this.auditForm.voucher = ''
    },
    //驳回
    remark(row) {
      this.id = row.id;
      this.dislogaudit = !0;
      this.auditForm.status = -1;
      this.auditForm.remark = ''
    },
    handleAudit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.id,
            status: this.auditForm.status,
          };
          if (data.status == 2) {
            data.voucher = this.auditForm.voucher;
            data.remit_amount = this.auditForm.remit_amount;
            this.$axios.post(this.$api.samecity.depositOutAudit, data).then(res => {
              if (res.code == 0) {
                this.$message.success('已打款');
                this.dislogaudit = !1;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          if (data.status == -1) {
            data.remark = this.auditForm.remark;
            this.$axios.post(this.$api.samecity.depositOutAudit, data).then(res => {
              if (res.code == 0) {
                this.$message.success('已驳回');
                this.dislogaudit = !1;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
